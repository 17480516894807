import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Hakkımızda | DriveSafe Hukuk Hizmetleri Hakkında
			</title>
			<meta name={"description"} content={"DriveSafe Legal Services olarak yolculuğumuz basit ama güçlü bir vizyonla başladı: trafik hukukunun genellikle karmaşık dünyasında gezinen sürücüler için bir umut ışığı ve uzmanlık olarak durmak. "} />
			<meta property={"og:title"} content={"Hakkımızda | DriveSafe Hukuk Hizmetleri Hakkında"} />
			<meta property={"og:description"} content={"DriveSafe Legal Services olarak yolculuğumuz basit ama güçlü bir vizyonla başladı: trafik hukukunun genellikle karmaşık dünyasında gezinen sürücüler için bir umut ışığı ve uzmanlık olarak durmak. "} />
			<meta property={"og:image"} content={"https://koala-hust.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://koala-hust.com/img/icon.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://koala-hust.com/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://koala-hust.com/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://koala-hust.com/img/icon.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://koala-hust.com/img/icon.png"} />
			<meta name={"msapplication-TileImage"} content={"https://koala-hust.com/img/icon.png"} />
		</Helmet>
		<Components.Header />
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-7"
		>
			<Override
				slot="SectionContent"
				flex-direction="column"
				sm-min-width="280px"
				lg-flex-wrap="wrap"
				display="flex"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
			>
				<Text
					as="h1"
					margin="0px 0px 24px 0px"
					font="--headline2"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Hakkımızda
				</Text>
				<Text
					as="h1"
					margin="0px 0px 0px 0px"
					font="--lead"
					color="--dark"
					width="50%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					lg-width="100%"
					sm-text-align="left"
				>
					DriveSafe Hukuk Hizmetleri olarak yolculuğumuz basit ama güçlü bir vizyonla başladı: trafik hukukunun genellikle karmaşık dünyasında gezinen sürücüler için bir umut ışığı ve uzmanlık olarak durmak. Misyonumuz, hız cezalarından park anlaşmazlıklarına kadar trafikle ilgili yasal sorunlarla karşılaşanlara olağanüstü yasal destek sağlamaktır.
				</Text>
			</Box>
			<Box
				width="100%"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="48px"
				lg-grid-gap="48px"
				lg-width="100%"
				sm-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
			>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px">
						Özel Savunuculuk
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
						Sürücülerin hakları için mücadele etmeye inanıyoruz. Ekibimiz, her davanın kendine özgü ihtiyaçlarına göre uyarlanmış kişiselleştirilmiş yasal stratejiler sunmaya kararlıdır.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
						Toplum Katılımı
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
						DriveSafe sadece bir hizmet değil, aynı zamanda bir toplum girişimidir. Sürücüleri yoldaki hakları ve sorumlulukları konusunda eğitmek için düzenli olarak atölye çalışmaları ve bilgilendirme oturumları düzenliyoruz.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
						Uygulamada Yenilikçilik
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
						Geleneksel hukuki yaklaşımlara saygı duymakla birlikte, müvekkillerimize daha etkili ve verimli çözümler sunmak için yenilikçi yöntemleri de benimsiyoruz.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section background="--color-darkL2" padding="60px 0" sm-padding="40px 0">
			<Box
				margin="-16px -16px -16px -16px"
				padding="0px 0px 0px 0px"
				display="flex"
				width=" "
				flex-wrap="wrap"
			>
				<Box padding="16px 16px 16px 16px" display="flex" width="50%" lg-width="100%">
					<Box
						padding="98px 64px 98px 64px"
						display="flex"
						flex-direction="column"
						background="--color-light"
						color="--dark"
					>
						<Text as="h2" margin="12px 0" font="--headline2" md-font="--headline3">
							Bizim Hikayemiz
						</Text>
						<Text
							as="h4"
							margin="12px 0"
							font="--base"
							color="--grey"
							letter-spacing="1px"
						>
							DriveSafe Hukuk Hizmetleri, adalete duyulan tutku ve sürücülerin karşılaştığı zorlukların derinlemesine anlaşılmasıyla ortaya çıktı. Sadece acil sorunları çözmekle kalmayıp aynı zamanda daha güvenli, daha bilinçli bir sürüş topluluğuna katkıda bulunan uzmanlaşmış yasal hizmetlere olan ihtiyacı fark ettik.
							<br />
							<br />
							Ekibimiz, trafik hukuku alanındaki uzmanlıkları ve sürücülerin haklarını koruma konusundaki ortak kararlılıklarıyla bir araya gelen farklı geçmişlere sahip bireylerden oluşmaktadır. Her bir üyemiz, hukuki hizmetlere yaklaşımımızı zenginleştiren benzersiz bir bakış açısı getirmektedir.
						</Text>
					</Box>
				</Box>
				<Box display="flex" width="50%" flex-wrap="wrap" lg-width="100%">
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="100%"
						lg-width="33.3333%"
						md-width="100%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 624px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://koala-hust.com/img/2.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="50%"
						lg-width="33.3333%"
						md-width="50%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 296px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://koala-hust.com/img/3.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="50%"
						lg-width="33.3333%"
						md-width="50%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 296px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://koala-hust.com/img/4.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});